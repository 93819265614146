import React, { useLayoutEffect, useRef } from "react";
import styles from "./Line.module.css";

const WideLine = ({ height, top, fromYPosition, gsap, darkWideLine }) => {
  const wideLineRef = useRef();
  const { WideLine, DarkLine } = styles;

  useLayoutEffect(() => {
    const lineAnimation = gsap.from(wideLineRef.current, {
      y: fromYPosition,
      duration: 2.5,
      ease: "power2.out",
      scrollTrigger: {
        trigger: wideLineRef.current,
      },
    });
    return () => {
      lineAnimation.kill();
    };
  }, []);

  return (
    <div
      ref={wideLineRef}
      style={{ height: height, top: top }}
      className={`${WideLine} ${darkWideLine ? DarkLine : ""}`}
    ></div>
  );
};

const Line = ({
  lineHeight,
  wideLineHeight,
  wideLineTopGap,
  gsap,
  fromAnimationPosition,
  alignment,
  whiteLine,
  darkWideLine,
}) => {
  const { Line } = styles;

  const lineStyles = { height: lineHeight };

  if (alignment === "left") {
    lineStyles.marginRight = "1.5rem";
  }

  if (alignment === "right") {
    lineStyles.marginLeft = "1.5rem";
  }

  if (whiteLine) {
    lineStyles.borderColor = "white";
  }

  return (
    <div style={lineStyles} className={Line}>
      <WideLine
        darkWideLine={darkWideLine}
        height={wideLineHeight}
        top={wideLineTopGap}
        gsap={gsap}
        fromYPosition={fromAnimationPosition}
      />
    </div>
  );
};

export default Line;
