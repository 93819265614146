import React from "react";
import darumaLogo from "../../assets/images/logo-daruma.png";
import Button from "../UI/Button/Button";

import styles from "./NotFound.module.css";

const NotFound = () => {
  const { NotFound } = styles;
  return (
    <div className={NotFound}>
      <img src={darumaLogo} alt="Daruma" />
      <h1>¡Ups! Esta página no existe.</h1>
      <Button primary navLink="/">
        Volver
      </Button>
    </div>
  );
};

export default NotFound;
