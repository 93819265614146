import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./Button.module.css";

const Button = (props) => {
  const location = useLocation();
  const buttonStyles = [styles.Button];
  let link = "";

  if (props.href) {
    link = props.href;
  } else {
    if (props.openAccount) {
      link = process.env.REACT_APP_ABRIR_CUENTA;
    } else if (props.login) {
      link = "/iniciar-sesion";
    } else if (props.whatsapp) {
      let darumaName = "Daruma";
      const pathname = location.pathname;
      if (pathname?.includes("/cuenta")) {
        darumaName = "Daruma%20Cuenta";
      }

      if (pathname?.includes("/pago")) {
        darumaName = "Daruma%20Pago";
      }

      link = `https://wa.me/5491138499171?text=Hola%2C%20quisiera%20agendar%20una%20demo%20en%20${darumaName}%20para%20mi%20empresa`;
    } else {
      link = "";
    }
  }

  if (props.primary) {
    buttonStyles.push(styles.Primary);
  }

  if (props.secondary) {
    buttonStyles.push(styles.Secondary);
  }

  if (props.tertiary) {
    buttonStyles.push(styles.Tertiary);
  }

  if (props.quaternary) {
    buttonStyles.push(styles.Quaternary);
  }

  if (props.quinary) {
    buttonStyles.push(styles.Quinary);
  }

  if (props.invertedPrimary) {
    buttonStyles.push(styles.InvertedPrimary);
  }

  if (props.invertedTertiary) {
    buttonStyles.push(styles.InvertedTertiary);
  }

  return (
    <>
      {props.navLink ? (
        <NavLink
          className={`${buttonStyles.join(" ")} ${
            props.largePadding ? "me-4" : ""
          } ${props.shortPadding ? "me-3" : ""} ${props.className ?? ""}`}
          to={props.navLink}
          style={props.style}
        >
          {props.children}
        </NavLink>
      ) : (
        <a
          href={link}
          rel="noopener noreferrer"
          target="_blank"
          style={props.style}
          className={`${buttonStyles.join(" ")} ${
            props.largePadding ? "me-4" : ""
          } ${props.shortPadding ? "me-3" : ""} ${props.className ?? ""}`}
        >
          {props.children}
        </a>
      )}
    </>
  );
};

export default Button;
