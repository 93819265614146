import React, { useEffect, useState, Suspense } from "react";
import AOS from "aos";
import { analytics } from "./firebase";
import "aos/dist/aos.css";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Routes, Route, useLocation } from "react-router-dom";
import DarumaSelection from "./components/DarumaSelection/DarumaSelection";
import ReactPixel from "react-facebook-pixel";

import NotFound from "./components/NotFound/NotFound";
import styles from "./App.module.css";

const Nosotros = React.lazy(() => import("./components/Nosotros/Nosotros"));
const Cuenta = React.lazy(() => import("./components/Cuenta/Cuenta"));
const Pago = React.lazy(() => import("./components/Pago/Pago"));
const NewHome = React.lazy(() => import("./components/NewHome/NewHome"));

const App = () => {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);
  const [offsetY, setOffsetY] = useState(0);
  const [width, setWidth] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [isSmallDesktop, setIsSmallDesktop] = useState(false);

  // Analytics & FB

  useEffect(() => {
    ReactPixel.init(
      "371532288093490",
      {},
      {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      }
    );

    ReactPixel.pageView();
  }, []);

  const trackPageView = () => {
    analytics.setCurrentScreen(window.location.pathname);
    analytics.logEvent("page_view", window.location.pathname);
  };

  useEffect(() => {
    trackPageView();
    ScrollTrigger.refresh(true);
  }, [location]);

  useEffect(() => {
    // Scroll Listener
    window.addEventListener("scroll", handleScroll);
    // AOS Animations
    AOS.init({
      offset: 250,
      delay: 0,
      duration: 200,
    });

    // Initialize width
    setWidth(window.innerWidth);

    // Resize listener
    window.addEventListener("resize", handleResize);

    // Recalculate AOS offsets and position on window resize
    window.addEventListener("resize", () => AOS.refresh());

    return () => {
      window.addEventListener("resize", () => AOS.refresh());
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (width <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if (width <= 1350) {
      setIsSmallDesktop(true);
    } else {
      setIsSmallDesktop(false);
    }
  }, [width]);

  const globalProps = {
    offsetY,
    styles,
    gsap,
    width,
    isMobile,
    isSmallDesktop,
    location,
  };

  return (
    <>
      <Suspense fallback="">
        <Routes>
          <Route
            path="/iniciar-sesion"
            element={<DarumaSelection {...globalProps}></DarumaSelection>}
          />
          <Route path="" element={<NewHome {...globalProps}></NewHome>} />
          <Route
            path="/cuenta/*"
            element={<Cuenta {...globalProps}></Cuenta>}
          />
          <Route
            path="/nosotros"
            element={<Nosotros {...globalProps}></Nosotros>}
          />
          <Route path="pago" element={<Pago {...globalProps}></Pago>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
