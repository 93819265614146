import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import "@fontsource/catamaran";
import "@fontsource/catamaran/600.css";
import "@fontsource/catamaran/800.css";

import "@fontsource/poppins";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/800.css";

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));
