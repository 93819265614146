import React from "react";
import Button from "../UI/Button/Button";
import Line from "../UI/Line/Line";
import { useNavigate } from "react-router-dom";
// import Intro from "./Intro";

import bigDaruma from "../../assets/images/big-daruma.png";
import darumaCuenta from "../../assets/images/daruma-cuenta.png";
import darumaPago from "../../assets/images/daruma-pago.png";
import darumaLogo from "../../assets/svg/daruma-logo.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/svg/whatsapp-orange.svg";
import { ReactComponent as MailIcon } from "../../assets/svg/mail-orange.svg";

import darumaSelectionStyles from "./DarumaSelection.module.css";

const DarumaSelection = (globalProps) => {
  const navigate = useNavigate();
  const { gsap, isMobile } = globalProps;
  const {
    BigDaruma,
    AccountCol,
    Content,
    LogoContainer,
    CustomHero,
    AccountsContainer,
    CustomContainer,
    ButtonContainer,
    CustomRow,
    SupportSection,
  } = darumaSelectionStyles;

  return (
    <div className={CustomHero}>
      {/* <Intro gsap={gsap} /> */}
      <section className={`${CustomContainer} ${LogoContainer}`}>
        <img src={darumaLogo} alt="Logo" onClick={() => navigate("/")} />
      </section>
      <section className={`${CustomContainer} ${AccountsContainer}`}>
        <img className={BigDaruma} src={bigDaruma} alt="Daruma" />
        <div className={CustomRow}>
          <div className={`${AccountCol} text-start`}>
            <div className="d-flex justify-content-start align-items-stretch h-100">
              <Line
                gsap={gsap}
                wideLineHeight="120px"
                wideLineTopGap="20px"
                fromAnimationPosition="35px"
                alignment="left"
              />
              <div className={Content}>
                <img src={darumaCuenta} alt="Daruma Cuenta" />
                <h2>
                  La primera cuenta inteligente {!isMobile && <br />} para
                  empresas que crecen.
                </h2>
                <p>
                  Simplificá cobros y pagos, invertí automáticamente el dinero
                  de tu empresa y recibí asesoramiento financiero personalizado.
                  Sin costos ni comisiones.
                </p>
              </div>
            </div>
            <div className={`${ButtonContainer} pl-1-5 pt-2`}>
              <Button quaternary navLink="/cuenta" className="me-5">
                Ver sitio
              </Button>
              <Button
                quinary
                href="https://app.midaruma.com.ar/login"
                className="ms-3"
              >
                Iniciar Sesión
              </Button>
            </div>
          </div>
          <div className={`${AccountCol} text-start`}>
            <div className="d-flex justify-content-start align-items-stretch h-100">
              <Line
                gsap={gsap}
                wideLineHeight="120px"
                wideLineTopGap="20px"
                fromAnimationPosition="35px"
                alignment="left"
              />
              <div className={Content}>
                <img src={darumaPago} alt="Daruma Pago" />
                <h2>
                  Cuando cobrar es más simple, {!isMobile && <br />} crecer es
                  más fácil.
                </h2>
                <p>
                  Los medios de pago del sector agropecuario integrados en una
                  misma plataforma. Para que cobres de forma online y segura,
                  sin terminales físicas.
                </p>
              </div>
            </div>
            <div className={`${ButtonContainer} pl-1-5 pt-2`}>
              <Button primary navLink="/pago" className="me-5">
                Ver sitio
              </Button>
              <Button
                secondary
                href="https://admin.pago.midaruma.com.ar"
                className="ms-3"
              >
                Iniciar Sesión
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className={SupportSection}>
        <h3> ¿Tenés alguna duda?</h3>
        <p>Contactate con nuestro soporte online permanente.</p>
        <div>
          <a href="https://wa.me/5491138499171">
            <WhatsappIcon />
          </a>
          <a href="mailto:soporte@midaruma.com.ar">
            <MailIcon />
          </a>
        </div>
      </section>
    </div>
  );
};

export default DarumaSelection;
